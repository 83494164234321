import request from '@/utils/request'


//数据字典
export function getDict() {
  return request({
    url: 'common/dict',
    method: 'get'
  })
}
