
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1608832444108",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "6424",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M11.890619 560.841915l289.525612 129.392453 559.250499-523.687567-490.85963 564.544554 371.343547 149.824946c8.948712 3.398751 19.168956-1.3595 22.367781-10.899995v-0.67975L1023.646842 0.375862 10.61109 526.798424c-8.956709 4.774246-12.147536 15.666243-8.316944 25.198741 2.55906 4.086498 5.757884 7.493247 9.596473 8.852747z m357.276716 462.398088L513.610259 857.764808l-144.442924-62.648979v228.132171z",
        "p-id": "6425"
      }
    }
  ]
})
    }
  