
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1608832850679",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "7887",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M508.928 546.304c-120.32 0-218.112-97.792-218.112-218.112S388.608 110.08 508.928 110.08 727.04 207.872 727.04 328.192s-97.792 218.112-218.112 218.112z m0-385.024c-92.16 0-166.912 74.752-166.912 166.912s74.752 166.912 166.912 166.912S675.84 420.352 675.84 328.192 601.088 161.28 508.928 161.28z",
        "p-id": "7888"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M851.456 884.736c-14.336 0-25.6-11.264-25.6-25.6 0-167.936-136.704-304.64-304.64-304.64s-304.64 136.704-304.64 304.64c0 14.336-11.264 25.6-25.6 25.6s-25.6-11.264-25.6-25.6c0-196.096 159.744-355.84 355.84-355.84s355.84 159.744 355.84 355.84c0 14.336-11.264 25.6-25.6 25.6zM293.888 451.072c-99.84 0-181.248-80.896-181.248-180.736 0-99.84 80.896-180.736 180.736-180.736 42.496 0 83.456 14.848 115.2 41.472l-32.768 38.912c-22.016-18.432-52.224-29.184-82.432-29.184-71.68 0-129.536 57.856-129.536 129.536s58.368 129.536 130.048 129.536v51.2z",
        "p-id": "7889"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M35.84 726.016c-14.336 0-25.6-11.264-25.6-25.6 0-161.792 131.584-293.376 293.376-293.376 14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6c-133.632 0-242.176 108.544-242.176 242.176 0 14.336-11.264 25.6-25.6 25.6zM736.768 458.24v-51.2c71.68 0 129.536-57.856 129.536-129.536s-57.856-129.536-129.536-129.536c-30.72 0-59.392 10.24-82.432 29.184l-32.768-39.424c32.256-27.136 72.192-41.472 115.2-41.472 99.84 0 180.736 80.896 180.736 180.736 0 100.352-80.896 181.248-180.736 181.248z",
        "p-id": "7890"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M994.304 726.016c-14.336 0-25.6-11.264-25.6-25.6 0-133.632-108.544-242.176-242.176-242.176-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6c161.792 0 293.376 131.584 293.376 293.376 0 14.336-11.264 25.6-25.6 25.6z",
        "p-id": "7891"
      }
    }
  ]
})
    }
  