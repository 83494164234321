
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1607776577675",
    "class": "icon",
    "viewBox": "0 0 1025 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "13725",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    "width": "64.0625",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M602.368301 1023.999994c-7.288471 0-14.456471-2.650353-20.118588-7.770353L289.686891 752.941172 120.485951 752.941172c-48.429176 0-120.470588-32.105412-120.470588-120.470588l0-180.705881c0-48.429176 32.105412-120.470588 120.470588-120.470588l169.140705 0 292.562822-263.348704c8.854588-8.011294 21.564235-9.938823 32.406588-5.12C625.558889 67.70447 632.485948 78.486588 632.485948 90.352941l0 903.529406c0 11.866353-6.927059 22.708706-17.829647 27.527529C610.741006 1023.1567 606.524536 1023.999994 602.368301 1023.999994zM120.485951 391.529409c-57.344 0-60.235294 50.176-60.235294 60.235294l0 180.705881c0 57.283764 50.176 60.235294 60.235294 60.235294l180.705881 0c7.469176 0 14.637176 2.770824 20.118588 7.770353L572.250654 926.238112 572.250654 157.997176 321.370655 383.759057C315.829008 388.758586 308.661008 391.529409 301.191832 391.529409L120.485951 391.529409zM752.956535 361.411763c-7.649882 0-15.420235-2.951529-21.323294-8.794353-11.745882-11.745882-11.745882-30.84047 0-42.586353l120.470588-120.470588c11.745882-11.745882 30.84047-11.745882 42.586353 0s11.745882 30.84047 0 42.586353l-120.470588 120.470588C768.37677 358.460233 760.726888 361.411763 752.956535 361.411763zM933.662416 572.235291l-180.705881 0C736.391829 572.235291 722.838888 558.742585 722.838888 542.117644s13.552941-30.117647 30.117647-30.117647l180.705881 0C950.347593 511.999997 963.780063 525.492703 963.780063 542.117644S950.347593 572.235291 933.662416 572.235291zM873.427123 903.529406c-7.649882 0-15.420235-2.951529-21.323294-8.794353l-120.470588-120.470588c-11.745882-11.745882-11.745882-30.84047 0-42.586353s30.84047-11.745882 42.586353 0l120.470588 120.470588c11.745882 11.745882 11.745882 30.84047 0 42.586353C888.847358 900.577877 881.197475 903.529406 873.427123 903.529406z",
        "p-id": "13726"
      }
    }
  ]
})
    }
  