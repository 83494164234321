
    var runtime = require('vue-svg-component-runtime')
    module.exports = {
      __esModule: true,
      default: runtime.svgComponent({
  "tag": "svg",
  "attrsMap": {
    "t": "1607776224397",
    "class": "icon",
    "viewBox": "0 0 1024 1024",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg",
    "p-id": "7871",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    "width": "64",
    "height": "64"
  },
  "children": [
    {
      "tag": "defs"
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M749.381818 923.927273H204.8c-74.472727 0-137.309091-62.836364-137.309091-134.981818V249.018182c0-74.472727 60.509091-134.981818 137.309091-134.981818h337.454545c18.618182 0 34.909091 16.290909 34.909091 34.909091s-16.290909 34.909091-34.909091 34.90909H204.8c-37.236364 0-69.818182 30.254545-69.818182 67.49091V791.272727c0 34.909091 32.581818 67.490909 69.818182 67.490909h542.254545c37.236364 0 69.818182-30.254545 69.818182-67.490909V381.672727c0-18.618182 16.290909-34.909091 34.909091-34.909091s34.909091 16.290909 34.909091 34.909091v407.272728c-2.327273 74.472727-62.836364 134.981818-137.309091 134.981818z",
        "p-id": "7872"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M716.8 781.963636H242.036364c-18.618182 0-34.909091-16.290909-34.909091-34.909091s16.290909-34.909091 34.909091-34.90909h474.763636c18.618182 0 34.909091 16.290909 34.909091 34.90909s-16.290909 34.909091-34.909091 34.909091zM781.963636 325.818182l-162.909091-160.581818 90.763637-90.763637c44.218182-44.218182 118.690909-44.218182 162.909091 0 23.272727 20.945455 34.909091 48.872727 34.909091 79.127273 0 30.254545-11.636364 58.181818-34.909091 81.454545L781.963636 325.818182z m-65.163636-162.909091l65.163636 65.163636L826.181818 186.181818c6.981818-9.309091 11.636364-18.618182 11.636364-32.581818s-4.654545-23.272727-13.963637-32.581818c-18.618182-18.618182-48.872727-18.618182-67.490909 0L716.8 162.909091z",
        "p-id": "7873"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M342.109091 665.6c-18.618182 0-34.909091-6.981818-46.545455-20.945455-16.290909-16.290909-23.272727-41.890909-16.290909-62.836363l23.272728-90.763637c2.327273-6.981818 6.981818-18.618182 16.290909-27.927272L667.927273 116.363636l162.909091 160.581819-351.418182 349.090909c-9.309091 9.309091-18.618182 13.963636-27.927273 16.290909h-2.327273l-90.763636 20.945454c-6.981818 2.327273-11.636364 2.327273-16.290909 2.327273z m23.272727-153.6l-20.945454 86.109091 88.436363-20.945455 302.545455-297.890909-65.163637-65.163636L365.381818 512z",
        "p-id": "7874"
      }
    },
    {
      "tag": "path",
      "attrsMap": {
        "d": "M393.309091 458.472727l93.090909 90.763637-41.890909 39.563636-93.090909-90.763636z",
        "p-id": "7875"
      }
    }
  ]
})
    }
  